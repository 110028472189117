// Toggle theme
export function toggleTheme() {
  const currentTheme = document.documentElement.getAttribute("data-theme");
  const newTheme = currentTheme === "dark" ? "light" : "dark";

  document.documentElement.setAttribute("data-theme", newTheme);
}
const toggleBtn = document.querySelector("#toggleTheme i");
toggleBtn.addEventListener("click", toggleTheme);

// Scorll to top
const scrollToTopButton = document.querySelector(".top");
function handleScroll() {
  if (window.scrollY > 300) {
    scrollToTopButton.classList.add("clicked");
  } else {
    scrollToTopButton.classList.remove("clicked");
  }
}
// The window scroll event
window.addEventListener("scroll", debounce(handleScroll, 200));
// Scroll top when the button is clicked
scrollToTopButton.addEventListener("click", () => {
  window.scrollTo({
    top: 0,
  });
});

// Debounce function
function debounce(func, wait) {
  let timeout;

  return function (...args) {
    const context = this;

    clearTimeout(timeout);

    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
